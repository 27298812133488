
import Vue from "vue";
import { HTTP } from "@/utils/http-common";
export default Vue.extend({
  data(): any {
    return {
      news: {
        data: [],
      },
      newMessage: false,
      publishMessage: "",
      itemId: "",
      editingModel: {
        content: "",
        title: "",
      },
    };
  },
  methods: {
    addMessage() {
      this.newMessage = "add";
      for (const key in this.editingModel) {
        if (this.editingModel.hasOwnProperty(key)) {
          this.editingModel[key] = "";
        }
      }
      this.editingModel.edition_id = "1";
    },
    startEditing(values: any) {
      this.newMessage = "edit";
      this.itemId = values.id;
      this.editingModel.title = values.title;
      this.editingModel.content = values.content;
    },
  },
  created() {
    HTTP.get("api/articles")
      .then((response) => {
        this.news.data = response.data.data.reverse();
      })
      .catch((e) => {
        // console.error(e);
      });
  },
});

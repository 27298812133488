var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"columns"},[_c('button',{staticClass:"btn",on:{"click":_vm.addMessage}},[_vm._v("Nowa wiadomość")]),(_vm.newMessage)?_c('edition-form',{attrs:{"newMessage":_vm.newMessage,"itemId":_vm.itemId,"editingModel":_vm.editingModel,"requestUrl":'api/article',"formLayout":[
      {
        formField: 'input',
        name: 'Tytuł wiadomość',
        placeholder: 'Tytuł wiadomości',
        model: 'title',
      },
      {
        formField: 'textarea',
        name: 'Treść wiadomość',
        placeholder: 'Treść wiadomości',
        model: 'content',
      },
    ]}}):_vm._e(),_c('table-data',{attrs:{"headers":['ID.', 'Tytuł', 'Treść'],"tableData":this.news.data,"adminCategory":'article',"searchItem":'title'},on:{"start-editing":function($event){return _vm.startEditing($event)}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }